import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { financeApi } from '../../services/api';
import { Button } from '../../components/Default/Button';
import { InvoiceModal } from './InvoiceModal';
import { Breadcrumb } from '../../components/LegacyV4Components';
import { useToast } from '../../hooks/toast';
import {
  Container,
  InvoicesWrapper,
  InvoiceCard,
  InvoiceTitle,
  InvoiceInfo,
  InvoiceItem,
  InvoiceFooter,
  InvoiceSubTitle,
} from './styles';

interface InvoiceItemType {
  description: string;
  quantity: number;
  price_cents: number;
  _id: string;
}

interface NewSecondCopy {
  status: string;
  url: string;
  id: string;
  dueDate: string;
}

export interface Invoice {
  _id: string;
  paymentUrl: string;
  paymentProviderId: string;
  payerDocument: string;
  company: {
    cnpj: string;
    stakeholderEmail: string;
  };
  dueDate: string;
  totalValue: number;
  status: string;
  items: InvoiceItemType[];
  email: string;
  secondCopies: {
    _id: string;
    paymentProvider: string;
    paymentProviderId: string;
    dueDate: string;
    status: string;
    originalInvoicePaymentProviderId: string;
    paymentUrl: string;
    createdAt: string;
  }[];
  cabControlCode: string;
  itemControlCode: string;
  createdAt: string;
}

export function parseInvoiceStatus(status: string) {
  switch (status) {
    case 'pending':
      return 'Pendente';
    case 'paid':
      return 'Pago';
    case 'canceled':
      return 'Cancelado';
    default:
      return status;
  }
}

export function SAPInvoices() {
  const { id: unitId } = useParams();
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
  const [isCreatingSecondCopy, setIsCreatingSecondCopy] = useState(false);
  const [daysToPay, setDaysToPay] = useState<number>(1);
  const { addToast } = useToast();

  function orderBy(data: Invoice[]) {
    return data.sort(
      (a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime(),
    );
  }

  const findInvoices = useCallback(async () => {
    await financeApi
      .get<{ invoices: Invoice[] }>(`/invoices/unit/${unitId}`)
      .then((response) => {
        const sortedInvoices = orderBy(response.data.invoices);
        setInvoices(sortedInvoices);
      })
      .catch((error) => {
        console.error('There was an error fetching the invoices!', error);
      });
  }, [unitId]);

  const handleInvoiceDetails = (invoice: Invoice) => {
    setSelectedInvoice(invoice);
  };

  const handleGenerateSecondCopy = async (invoiceId: string) => {
    setIsCreatingSecondCopy(true);
    try {
      if (daysToPay !== null && (daysToPay < 1 || daysToPay > 30)) {
        throw new Error('O prazo para pagamento deve ser entre 1 e 30 dias');
      }

      const secondCopy = await financeApi.post<NewSecondCopy>(
        `/invoices/create/second-copy`,
        {
          invoiceId,
          days: daysToPay,
        },
      );

      if (!secondCopy?.data?.status) {
        throw new Error('Error ao gerar segunda via');
      }

      const updatedInvoices = invoices.map((invoice) => {
        if (invoice._id === invoiceId) {
          return {
            ...invoice,
            secondCopies: [
              {
                _id: secondCopy.data.id,
                paymentProvider: 'iugu',
                paymentProviderId: 'Processando',
                originalInvoicePaymentProviderId: 'Processando',
                dueDate: secondCopy.data.dueDate,
                status: 'pending',
                createdAt: new Date().toString(),
                paymentUrl: secondCopy.data.url,
              },
              ...invoice.secondCopies,
            ],
          };
        }
        return invoice;
      });

      setInvoices([...updatedInvoices]);
      setSelectedInvoice(null);
    } catch (error) {
      console.error('Erro ao gerar segunda cópia:', error);
      addToast({
        type: 'error',
        title: 'Erro ao gerar segunda via',
        description: error.message,
      });
    }
    setIsCreatingSecondCopy(false);
  };

  const closeModal = () => {
    setSelectedInvoice(null);
  };

  useEffect(() => {
    findInvoices();
  }, [findInvoices]);

  return (
    <Container>
      <Breadcrumb
        breadcrumbs={[
          { title: 'Unidades', link: '/unidades' },
          { title: 'Minha Unidade', link: `/unit/view/head/${unitId}` },
          { title: 'Faturas SAP', link: '' },
        ]}
      />

      <InvoiceTitle>Últimas Faturas Vencidas</InvoiceTitle>
      <InvoiceSubTitle>Faturas vencidas em até 20 dias.</InvoiceSubTitle>
      <InvoicesWrapper>
        {invoices.map((invoice) => (
          <InvoiceCard key={invoice._id}>
            <InvoiceInfo>
              <p>
                <strong>Email:</strong> {invoice.email}
              </p>
              <p>
                <strong>Documento Cliente:</strong> {invoice.payerDocument}
              </p>
              <p>
                <strong>Gerado em:</strong>{' '}
                {new Date(invoice.createdAt).toLocaleDateString()}
              </p>
              <p>
                <strong>Vencimento:</strong>{' '}
                {new Date(invoice.dueDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Status:</strong> {parseInvoiceStatus(invoice.status)}
              </p>
              <p>
                <strong>Valor Total:</strong> R${invoice.totalValue / 100}
              </p>
              <p>
                <strong>ID Iugu:</strong> {invoice.paymentProviderId}
              </p>
              <p>
                <strong>ID SAP:</strong> {invoice.cabControlCode}
              </p>
              <p>
                <strong>ID SAP Item:</strong> {invoice.itemControlCode}
              </p>
              <p>
                <strong>Quantidade de segundas vias:</strong>{' '}
                {invoice.secondCopies.length || 0}
              </p>
            </InvoiceInfo>
            <div>
              {invoice.items.map((item) => (
                <InvoiceItem key={item._id}>
                  <p>
                    {item.quantity} x {item.description}
                  </p>
                  <p>R${item.price_cents / 100}</p>
                </InvoiceItem>
              ))}
            </div>
            <InvoiceFooter>
              <Button small onClick={() => handleInvoiceDetails(invoice)}>
                Detalhes
              </Button>
            </InvoiceFooter>
          </InvoiceCard>
        ))}
      </InvoicesWrapper>

      {selectedInvoice && (
        <InvoiceModal
          invoice={selectedInvoice}
          onClose={closeModal}
          onGenerateSecondCopy={handleGenerateSecondCopy}
          isCreatingSecondCopy={isCreatingSecondCopy}
          setDaysToPay={setDaysToPay}
          daysToPay={daysToPay}
        />
      )}
    </Container>
  );
}
