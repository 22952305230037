import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 2rem 0;
  max-width: 1480px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--lab-ds-core-color-white-0);
  border-radius: 20px;
  color: var(--lab-ds-core-color-black-10);
`;

export const InvoiceTitle = styled.h1`
  font-size: 1.5rem;
  color: var(--lab-ds-core-color-black-10);
  margin-top: 1rem;
`;
export const InvoiceSubTitle = styled.p`
  font-size: 1rem;
  margin-bottom: 2rem;
  color: var(--lab-ds-core-color-gray-40);
`;

export const InvoicesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 2fr));
  gap: 2rem;
`;

export const InvoiceCard = styled.div`
  background-color: var(--lab-ds-core-color-white-10);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const InvoiceInfo = styled.div`
  margin-bottom: 1rem;
  h3 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  p {
    margin: 0.5rem 0;
    font-size: 1rem;
  }
`;

export const InvoiceItem = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--lab-ds-core-color-gray-20);
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: var(--lab-ds-core-color-gray-70);
`;

export const InvoiceFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 500px;
  max-height: 80vh;
  overflow-y: auto;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  h3 {
    margin-top: 1.5rem;
  }
`;

export const SecondCopieContainer = styled.div`
  border-top: 2px solid var(--lab-ds-core-color-gray-20);
  margin-top: 1rem;
  padding: 0.5rem;
  padding-top: 1rem;
  background: var(--lab-ds-core-color-gray-10);
  border-radius: 0.5rem;

  &:last-child {
    background: transparent;
  }
`;

export const SecondCopieFormContainer = styled.section`
  margin-top: 2rem;

  div {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  lab-ds-button {
    margin-top: 1rem;
  }

  input {
    width: 100%;
    height: 100%;
    margin-right: 0.5rem;
    padding: 0.5rem 0.5rem;
    color: var(--secondary-light);
    border: 2px solid var(--grayscale-10);
    transition: 0.2s ease all;
    border-radius: 0.25rem;
    flex: 1;
  }
`;
