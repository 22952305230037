import React, {
  InputHTMLAttributes,
  useState,
  SetStateAction,
  useRef,
  useEffect,
} from 'react';
import { IoIosHelp } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';
import { MdClose, MdExpandMore } from 'react-icons/md';
import { Chip } from '../Chip';
import * as S from './styles';

interface MultInputSearch extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  borderColor?: string;
  required?: boolean;
  placeholder: string;
  options: Array<InputItens>;
  maxItem: number;
  info?: string;
  itensSelected: Array<InputItens>;
  setItensSelected: React.Dispatch<SetStateAction<Array<InputItens>>>;
  error?: string;
}

interface InputItens {
  label: string;
  info?: string;
}

export function MultInputSearch({
  label,
  required,
  placeholder,
  maxItem,
  options,
  info,
  itensSelected,
  setItensSelected,
  error,
  ...rest
}: MultInputSearch) {
  const [search, setSearch] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const searchedItems = options.filter((item) =>
    item?.label?.toUpperCase().includes(search?.toUpperCase()),
  );

  const CloseClickOutside = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  CloseClickOutside(wrapperRef);

  const addItem = (index: number) => {
    const existing =
      itensSelected.filter(
        (select) => select.label === searchedItems[index].label,
      ).length > 0;

    if (existing) {
      const indexItem = itensSelected
        .map((item) => item.label)
        .indexOf(searchedItems[index].label);

      itensSelected.splice(indexItem, 1);
      setItensSelected([...itensSelected]);
      return;
    }

    const aux = searchedItems[index];
    itensSelected.push(aux);
    setItensSelected([...itensSelected]);
    setIsOpen(!isOpen);
  };

  const deleteItem = (index: number) => {
    itensSelected.splice(index, 1);
    setItensSelected([...itensSelected]);
  };

  return (
    <S.InputContent ref={wrapperRef}>
      <ReactTooltip />
      <S.Row>
        <S.InputLabel>
          {label}
          {required && <S.Required>*</S.Required>}
        </S.InputLabel>
        {info ? (
          <S.Tooltip>
            <IoIosHelp size={24} data-tip={info} />
          </S.Tooltip>
        ) : null}
      </S.Row>
      <S.Input
        {...rest}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <S.InputItens>
          {itensSelected.length > 0 ? (
            itensSelected.map((item, index) => (
              <Chip
                key={Number(index)}
                item={item.label}
                remove={() => {
                  deleteItem(index);
                }}
              />
            ))
          ) : (
            <S.Placeholder>{placeholder}</S.Placeholder>
          )}
        </S.InputItens>
        {isOpen ? <MdClose /> : <MdExpandMore />}
      </S.Input>
      {isOpen ? (
        <S.ContentSearch>
          <S.InputSearch
            type="text"
            placeholder="Filtrar"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <S.Options>
            {searchedItems.map((item, index) => (
              <S.Row>
                <ReactTooltip />

                <S.Itens
                  disabled={maxItem === itensSelected.length}
                  onClick={() => addItem(index)}
                  checked={
                    itensSelected.filter(
                      (select) => select.label === item.label,
                    ).length > 0
                  }
                  key={Number(index)}
                >
                  {item.label}
                </S.Itens>
                {item.info ? (
                  <S.Tooltip>
                    <IoIosHelp size={24} data-tip={item.info} />
                  </S.Tooltip>
                ) : null}
              </S.Row>
            ))}
          </S.Options>
        </S.ContentSearch>
      ) : null}
      <S.Error>{error}</S.Error>
    </S.InputContent>
  );
}
