import { FiCopy } from 'react-icons/fi';
import { LabDsButton } from 'v4web-components-react';
import { Invoice, parseInvoiceStatus } from '.';
import { useToast } from '../../hooks/toast';
import {
  ModalContainer,
  ModalContent,
  SecondCopieContainer,
  SecondCopieFormContainer,
} from './styles';

interface InvoiceModalProps {
  invoice: Invoice;
  onClose: () => void;
  onGenerateSecondCopy: (invoiceId: string) => void;
  isCreatingSecondCopy: boolean;
  daysToPay: number | null;
  setDaysToPay: (days: number) => void;
}

export function InvoiceModal({
  invoice,
  onClose,
  onGenerateSecondCopy,
  isCreatingSecondCopy,
  daysToPay,
  setDaysToPay,
}: InvoiceModalProps) {
  const { addToast } = useToast();

  const secondCopiesOrdered = invoice.secondCopies.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  );

  return (
    <ModalContainer>
      <ModalContent>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <h2>Detalhes da Fatura</h2>
          <LabDsButton
            size="small"
            variant="danger-outlined"
            onClick={onClose}
            disabled={isCreatingSecondCopy}
            label="Fechar"
          />
        </div>
        <p>
          <strong>Email: </strong>
          {invoice.email}
        </p>
        <p>
          <strong>Valor: </strong>
          R${invoice.totalValue / 100}
        </p>
        <p>
          <strong>URL Original: </strong>
          <FiCopy
            style={{ cursor: 'pointer', color: 'var(--primary-dark)' }}
            onClick={() => {
              navigator.clipboard.writeText(invoice.paymentUrl);
              addToast({
                type: 'success',
                title: 'URL copiada',
                description: `${invoice.paymentUrl}`,
              });
            }}
          />
        </p>

        <SecondCopieFormContainer>
          <h3>Gerar Nova Segunda Via</h3>
          <div>
            <p>Número de dias até o próximo vencimento, Exemplo: 2</p>
            <input
              name="daysToPay"
              maxLength={2}
              min={1}
              max={30}
              type="number"
              placeholder="Dias para pagar, exemplo: 2"
              value={String(daysToPay)}
              onChange={({ target }) => {
                setDaysToPay(Number(target.value));
              }}
            />

            <LabDsButton
              disabled={isCreatingSecondCopy}
              onClick={() => onGenerateSecondCopy(invoice._id)}
              loading={isCreatingSecondCopy}
              size="small"
              maxWidth
              label="Gerar Nova Segunda Via"
            />

            <p
              style={{
                marginTop: '1rem',
                color: 'var(--lab-ds-core-color-gray-50)',
              }}
            >
              Ao gerar uma segunda via, a via anterior é cancelada!
            </p>
          </div>
        </SecondCopieFormContainer>

        {secondCopiesOrdered.length > 0 && <h3>Histórico</h3>}
        <section>
          {secondCopiesOrdered?.map((secondCopy) => (
            <SecondCopieContainer key={secondCopy._id}>
              <p>
                <strong>Vencimento:</strong>{' '}
                {new Date(secondCopy.dueDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Status:</strong> {parseInvoiceStatus(secondCopy.status)}
              </p>
              <p>
                <strong>ID Iugu:</strong> {secondCopy.paymentProviderId}
              </p>
              <p>
                <strong>ID Iugu Fatura Original:</strong>{' '}
                {secondCopy.originalInvoicePaymentProviderId}
              </p>
              <p>
                <strong>URL: </strong>
                <FiCopy
                  style={{ cursor: 'pointer', color: 'var(--primary-dark)' }}
                  onClick={() => {
                    navigator.clipboard.writeText(secondCopy.paymentUrl);
                    addToast({
                      type: 'success',
                      title: 'URL copiada',
                      description: `${secondCopy.paymentUrl}`,
                    });
                  }}
                />
              </p>

              <p>
                <strong>Gerado em:</strong>{' '}
                {new Date(secondCopy.createdAt).toLocaleDateString()}
              </p>
            </SecondCopieContainer>
          ))}
        </section>
      </ModalContent>
    </ModalContainer>
  );
}
